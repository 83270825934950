<template>
  <div
    class="d-flex flex-column  justify-center align-center secondary-lighten rounded"
  >
    <v-expand-transition>
      <div v-if="previewIsShown && creatives.length && adNetwork.settings.inPagePreviewTagId" class="d-flex flex-column align-center width-100">
        <v-carousel
          cycle
          class="pa-1"
          hide-delimiter-background
          show-arrows-on-hover
          height="310"
          :show-arrows="creatives.length > 1"
          hide-delimiters
        >
          <v-carousel-item v-for="(creative, i) in creatives" :key="i">
            <in-page-preview
              class="rounded"
              :settings="getPreviewParams(creative)"
              :tag-id="adNetwork.settings.inPagePreviewTagId"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-expand-transition>
    <div v-if="previewIsShown && !ad.model.creatives.length">
      <v-icon class="d-flex justify-center align-center fill-height py-6" size="100">
        mdi-cloud-upload-outline
      </v-icon>
    </div>
    <c-btn
      class="my-2"
      small
      depressed
      :label="previewIsShown ? $t('ads.hide_preview') : $t('ads.show_preview')"
      color="primary"
      @click="onPreviewBtnClick()"
    />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Ads from '@/services/classes/Ads.js'
  import InPagePreview from '@/components/ads/InPagePreview.vue'

  export default {
    name: 'InPageCreativePreview',
    components: { InPagePreview, CBtn },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    data() {
      return {
        previewIsShown: false
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('collections', ['defaultInPageSkin']),

      creatives() {
        return this.ad.model.creatives
      }
    },
    methods: {
      onPreviewBtnClick() {
        this.previewIsShown = !this.previewIsShown
        this.$emit('expand', this.previewIsShown)
      },
      getPreviewParams(creative) {
        return {
          description: creative.description,
          tdsId: creative.inPageSkin.tdsId || this.defaultInPageSkin?.tdsId,
          title: creative.title,
          icon: creative.icon.src,
          image: creative.image.src
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep .v-carousel__item {
    align-items: center;
  }
  .width-100 {
    width: 100%;
  }
</style>
